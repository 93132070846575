.footer{
    width: 100%;
    height: 120px;
    background: #8aa301;
    display: flex;
    justify-content: top;
    align-items: center;
    flex-direction: column;
    font-family: $mon;
    h4{
        color: #fff;

    }
    h5{
        color: #fff;
        font-size: 10px;
        justify-self: flex-end;

        a{
            color: rgb(236, 204, 204);
        }
    }


    img{
        padding-top: 10px;
        width: 200px;
        filter: drop-shadow(0.5px 0.5px 0 rgb(255, 255, 255)) 
    }
}