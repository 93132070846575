.contacts{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    iframe{
        width: 100%;
        overflow-y: hidden;
    }
    .line3{
        &:nth-child(1){
            margin-bottom: 0px;
            margin-top: 50px
            
        };
        width: 300px;
        height: 2px;
        background-color: green;
        margin-bottom: 20px;
        

    }
    h1{
        text-align: center;
        text-transform: uppercase;
        font-family: $squada;
        font-size: 60px;
        font-weight: bold;
        font-weight: bold;
        right: 0px;
        color: #fff;
        -webkit-text-stroke: 0.001mm rgb(0, 255, 0);
    }
}
