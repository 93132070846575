.countries{
    width: 100%;
    height: 100%;
    // padding: 60px 30px 30px 30px;
    padding-top: 40px;
    background: rgb(227, 235, 207);
    background: linear-gradient(180deg, rgb(233, 241, 209) 51%, rgb(242, 252, 217) 78%, rgba(255,255,255,1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    

    .line3{
        &:nth-child(1){
            margin-bottom: 0px;
            
        };
        width: 300px;
        height: 2px;
        background-color: green;
        margin-bottom: 20px;

    }
    h1{
        text-transform: uppercase;
        font-family: $squada;
        font-size: 60px;
        font-weight: bold;
        font-weight: bold;
        right: 0px;
        color: #fff;
        -webkit-text-stroke: 0.001mm rgb(0, 255, 0);
    }
    .country-list{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    .ser{
        z-index: 0;
        padding: 10px;
        border: 1px rgba(128, 128, 128, 0.151) solid;
    }
    .ser{
            padding: 40px;
            border: 2px rgba(29, 32, 31, 0.336) solid;
            border-radius: 1px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: all ease .3s;
            p{
                opacity: 0;
                transition: all ease-in 0.5s;
                text-align: center;
                padding: 20px;
                color: #ffffff;
                font-family: $mon;
            }
            h3{
                text-transform: uppercase;
                text-align: center;
                opacity: 1;
                font-weight: bolder;
                color: rgb(245, 253, 245);
                font-size: 65px;
                transition: all ease-out 0.3s;
                -webkit-text-stroke-width: 0.001px;
                -webkit-text-stroke-color: rgb(0, 255, 0);
                z-index: 1;
                position: absolute;
                top: 1;
                left: 1;
                font-family: $squada;
            }

            &:hover{
                h3{
                    opacity: 0;
                    z-index: -1;
                    
                }
                p{
                    opacity: 1;
                    z-index: 1;
                }
                
            }
        }
        .ser-1{
            background: url(../assets/images/chexiya.png);
            background-size: cover;
            background-position: center;

            &:hover{
                background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(71, 70, 70, 0.7)), url(../assets/images/chexiya.png);
                background-size: cover;
                background-position: center;
            }
        }
        .ser-2{
            background: url(../assets/images/latviya.jpg);
            background-size: cover;
            background-position: center;

            &:hover{
                background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(71, 70, 70, 0.7)), url(../assets/images/latviya.jpg);
                background-size: cover;
                background-position: center;
            }
        }
        .ser-3{
            background: url(../assets/images/slovakiya.jpg);
            background-size: cover;
            background-position: center;

            &:hover{
                background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(71, 70, 70, 0.7)), url(../assets//images/slovakiya.jpg);
                background-size: cover;
                background-position: center;
            }
        }
        .ser-4{
            background: url(../assets//images/poland.jpg);
            background-size: cover;
            background-position: center;

            &:hover{
                background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(71, 70, 70, 0.7)), url(../assets//images/poland.jpg);
                background-size: cover;
                background-position: center;
            }
        }
        .ser-5{
            background: url(../assets/images/litva.jpg);
            background-size: cover;
            background-position: center;

            &:hover{
                background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(71, 70, 70, 0.7)), url(../assets/images/litva.jpg);
                background-size: cover;
                background-position: center;
            }
        }
        .ser-6{
            background: url(../assets/images/germany.jpg);
            background-size: cover;
            background-position: center;

            &:hover{
                background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(71, 70, 70, 0.7)), url(../assets/images/germany.jpg);
                background-size: cover;
                background-position: center;
            }
        }
        .ser-7{
            background: url(../assets/images/korea.jpg);
            background-size: cover;
            background-position: center;

            &:hover{
                background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(71, 70, 70, 0.7)), url(../assets/images/korea.jpg);
                background-size: cover;
                background-position: center;
            }
        }
        .ser-8{
            background: url(../assets/images/russia.jpg);
            background-size: cover;
            background-position: center;

            &:hover{
                background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(71, 70, 70, 0.7)), url(../assets/images/russia.jpg);
                background-size: cover;
                background-position: center;
            }
        }
        .ser-9{
            background: url(../assets/images/turkey.jpg);
            background-size: cover;
            background-position: center;

            &:hover{
                background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(71, 70, 70, 0.7)), url(../assets/images/turkey.jpg);
                background-size: cover;
                background-position: center;
            }
        }
    }

}

@media only screen and (max-width: 1000px) and (min-width: 768px){
    .countries{

        .country-list{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

}
    }


}
@media only screen and (max-width: 768px){
    .countries{

        .country-list{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

}
    }


}