

.main-page{
    background-image: url(../assets/images/bg.jpg); background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: space-around;
    align-items: center;


    .fake{
        width: 50%;

    }
    .info{
        padding: 10px;
        font-family: $squada;
        font-size: 40px;
        font-weight: bold;
        right: 0px;
        color: green;
        -webkit-text-stroke: 0.001mm rgba(255, 255, 255, 0.342);
        height: 280px;
        width: 45%;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.5);
        
        transition: all ease-out 0.4s;
        border-radius: 10px;
        

        button{
            color: #fff;
            margin-top: 20px;
            border: 1px greenyellow solid;
            height: 50px;
            width: 150px;
            border-radius: 5px;
            font-family: $squada;
            font-size: 20px;
            background-color: green;
            cursor: pointer;
            transition: all ease .3s;
            a{
                text-decoration: none;
                color: #fff;
            }
            i{
                transition: ease transform .3s;
            }


            &:hover{
                border-color: green;
                
                background-color: rgba(255, 255, 255, 0.781);
                
                font-size: 21px;

                a{
                    color: green;
                }

                i{
                    transform: rotate(320deg);
                }
            }
        }

        &:hover{
            // border: 3px solid grey;

            border-radius: 10px;
        }
    }
}

//social links

.social-links{
    z-index: 1;
    position: fixed;
    right: 18px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    color: green;
    background-color: rgba(255, 255, 255, 0.678);
    padding: 6px;
    border-radius: 10px;

    a{
        text-decoration: none;
        

        i{
            color: green;
            

        }
    }

    .line1{
        height: 1px;
        width: 28px;
        background-color: grey;
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

@media only screen and (max-width: 1050px) and (min-width: 780px){
    .main-page{
        


        .info{
            margin-top: 200px;
            width: 60%;
            margin-right: 18px;
            font-size: 35px;
        }
    }
}

@media only screen and (max-width: 779px){
    .main-page{
        background-image: url(../assets/images/bg-mobile.png);

        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        .fake{
            height: 50%;
        }

        .info{
            max-height: 250px;
            width: 70%;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 20px;
        }
    }
}