@import 'NavBar';
@import 'Main';
@import 'Countries';
@import 'Contacts';
@import 'Footer';
@import 'https://fonts.googleapis.com/css2?family=Lobster&family=Montserrat&family=Squada+One&display=swap';

// varibles
$lobster: 'Lobster', cursive;
$mon: 'Montserrat', sans-serif;
$squada: 'Squada One', cursive;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

