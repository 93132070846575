$lobster: 'Lobster', cursive;
$mon: 'Montserrat', sans-serif;
$squada: 'Squada One', cursive;

.mobile-menu{
    display: none;
}
.navbar{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    background-color: rgba(208, 255, 0, 0.363);
    color: #20aad8;
    position: fixed;
    z-index: 1;
    // -webkit-text-stroke: 0.5px rgb(255, 255, 255);
    

    i{
        display: none;
    }
    .brand{
        align-self: center;
        

        img{
            width: 200px;
            filter: drop-shadow(0.5px 1px 0 #fff) 

        }
    }

    //Menu list

    .nav-menu{
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        width: 30%;
        justify-content: flex-end;
        z-index: 1;


        li{
            font-family: $mon;
            font-weight: bolder;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            transition: all ease 0.5s;
            transition: background-color ease-in-out 0.3s;
            font-size: 20px;
            filter: drop-shadow(1px 1px rgb(168, 252, 73))
                    drop-shadow(-1px -1px #fff);
        }
        li:hover{
            
            text-decoration: underline;
            background-color: #fff;
            color: green;
            cursor: pointer;

        }
        

        .line{
            width: 0.5px;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.151);
            border-radius: 50px;
        }


    }

    
}


@media only screen and (max-width: 780px) {



    .show-menu{
        display: flex;
        transition: all ease-out 0.2s;
    }
    
    .mobile-menu{
        position: fixed;
        z-index: 1;
        top: 60px;
        display: flex;
        width: 100%;
        height: 60px;
        justify-content: center;
        background-color: rgba(208, 255, 0, 0.363);
        color: green;
        


        .nav-menu-mobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        width: 100%;
        justify-content: flex-end;
        z-index: 1;


        li{
            font-family: $mon;
            font-weight: bolder;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            transition: all ease 0.5s;
            transition: background-color ease-in-out 0.3s;
            font-size: 20px;
            filter: drop-shadow(1px 1px rgb(168, 252, 73))
                    drop-shadow(-1px -1px #fff);
        }
        li:hover{
            
            text-decoration: underline;
            background-color: #fff;
            color: green;
            cursor: pointer;

        }
        

        .line{
            width: 0.5px;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.151);
            border-radius: 50px;
        }


    }

    }


    
  .navbar{


      justify-content: center;
      

      i{
          display: block;
          position: inherit;
          right: 18px;
          top: 15px;
          font-size: 30px;
          
      }


      .fa-bars{
          justify-self: end;
          align-self: center;
      }



      .nav-menu{
          display: none;
          align-self: flex-end;
      }
  }

  
}